import React, { useEffect, useState } from 'react'
import "./Works.css"
import {AppWrap, MotionWrap} from "../../Wrapper"
import { motion} from "framer-motion"
import {AiFillEye ,AiFillGithub} from "react-icons/ai"
import {urlFor , client} from "../../Client"



const Work = () => {
  const [ActiveFilter , setActiveFilter] = useState("All")
  const [animateCard , setAnimateCard] = useState({y:0, opacity:1})
  const [works  , setWorks] = useState([])
  const [filterWorks  , setFilterWorks] = useState([])


  // useEffect(()=>{

  //   const query = "*[_type == 'Works']"
  //   client.fetch(query)
  //   .then((data)=>{
  //     setWorks(data)
  //     setFilterWorks(data)
  //   })

  // },[])
  
  useEffect(() => {
    const query = '*[_type == "works"]';

    client.fetch(query).then((data) => {
      setWorks(data);
      setFilterWorks(data);
    });
  }, []);


  const handleWorkFilter = (item)=>{
      setActiveFilter(item)
      setAnimateCard([{ y: 100, opacity: 0 }]);

      setTimeout(() => {
        setAnimateCard([{ y: 0, opacity: 1 }]);
  
        if (item === 'All') {
          setFilterWorks(works);
        } else {
          setFilterWorks(works.filter((work) => work.tags.includes(item)));
        }
      }, 500);
  }
  return (
    <>
        <h2 className="head-text" >
        My Creative <span>Portfolio</span> Section
      </h2>
      <div className="app__work-filter">
        {['WebApp' , 'React JS' , 'JavaScript' , 'Web Design' , 'All'].map((item,index)=>(
          <div
          key={index}
          onClick={()=>{handleWorkFilter(item)}}
          className={`app__work-filter-item app__flex p-text ${ActiveFilter=== item ? "item-active" : ""}`}
          >
              {item}
          </div>
        ))}
      </div>
      <motion.div
      animate={animateCard}
      transition={{duration:0.5 , delayChildren:0.5}}
      className="app__work-portfolio"
      >
        {filterWorks.map((work , index)=>(
          <div className="app__work-item app__flex" key={index}>
              <div className="app__work-img app__flex">
                <img src={urlFor(work.imgUrl)} alt="" />
                <motion.div
                whileHover={{opacity:[0,1]}}
                transition={{duration:0.5 , staggerChildren:0.5 ,ease:"easeInOut"}}
                className="app__work-hover app__flex"
                >
                  <a href={work.projectLink} target="_blank" >
                  <motion.div
                  whileInView={{scale:[0,1]}}
                    whileHover={{scale:[1,0.9]}}
                    transition={{duration:0.5 }}
                    className="app__flex"
                  >
                    <AiFillEye/>
                  </motion.div>
                  </a>

                </motion.div>
              </div>
              <div className="app__work-content app__flex">
              <h4 className="bold-text">{work.title}</h4>
              <p className="p-text" style={{marginTop:10}}>{work.description}</p>

              <div className='app__work-tags app__flex'>
              <p className="p-text">{work.tags[0]}</p>
              </div>
              </div>
          </div>
        ))}

      </motion.div>
    </>
  )
}

export default AppWrap(
  MotionWrap( Work , "app__works") ,
   'Works',
   "app__primarybg"
   )
 
